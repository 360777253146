import { useTranslation } from 'react-i18next';
import { getTextClassNames } from './Text.helper';

export type TextVariant =
  | 'none'
  | 'success'
  | 'danger'
  | 'attention'
  | 'discrete'
  | 'complementary'
  | 'primary'
  | 'help';

export type FontSize =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'titleLarge'
  | 'titleSmall'
  | 'bodyLarge'
  | 'body'
  | 'label';

export type FontWeight = 'regular' | 'medium' | 'bold';

export interface TextProps {
  text: string;
  textKey?: string;
  fontSize?: FontSize;
  fontWeight?: FontWeight;
  fontStyle?: 'italic';
  variant?: TextVariant;
  truncate?: 'oneLine' | 'twoLines' | 'tenLines';
  textAlign?: 'left' | 'center' | 'right';
  testId?: string;
}

export const Text = (props: TextProps) => {
  const { t } = useTranslation();
  const { text, textKey } = props;
  return (
    <span className={getTextClassNames(props)} data-testid={props.testId}>
      {textKey ? t(textKey, text) : text}
    </span>
  );
};
