var __defProp = Object.defineProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};

// src/lib/utils/index.ts
var utils_exports = {};
__export(utils_exports, {
  asyncTimeout: () => asyncTimeout,
  isValidEmail: () => isValidEmail
});

// src/lib/utils/utils.ts
function isValidEmail(email) {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [localPart, domain] = email.split("@");
  if (!localPart || !domain)
    return false;
  const maxLocalPartLength = 64;
  const maxDomainPartLength = 255;
  const maxEmailLength = 320;
  return regex.test(email) && email.length <= maxEmailLength && localPart.length <= maxLocalPartLength && domain.length <= maxDomainPartLength && !email.includes("..");
}
var asyncTimeout = (duration = 0) => new Promise((resolve) => setTimeout(resolve, duration));

// src/lib/constants/index.ts
var constants_exports = {};
__export(constants_exports, {
  API_CONFIG: () => API_CONFIG,
  ResponseCode: () => ResponseCode
});

// src/lib/constants/response-code.ts
var ResponseCode = /* @__PURE__ */ ((ResponseCode2) => {
  ResponseCode2["ACADEMY_ACCESS_DENIED"] = "ACADEMY_ACCESS_DENIED";
  ResponseCode2["INCORRECT_PASSWORD"] = "INCORRECT_PASSWORD";
  ResponseCode2["TOO_MANY_ATTEMPTS"] = "TOO_MANY_ATTEMPTS";
  ResponseCode2["COOKIE_EXPIRED"] = "COOKIE_EXPIRED";
  return ResponseCode2;
})(ResponseCode || {});
var API_CONFIG = {
  GET_LIMIT_MAX: 100,
  GET_LIMIT_DEFAULT: 20,
  GET_OFFSET_DEFAULT: 0
};

// src/lib/enums/index.ts
var enums_exports = {};
__export(enums_exports, {
  Language: () => Language
});

// src/lib/enums/language.enum.ts
var Language = /* @__PURE__ */ ((Language2) => {
  Language2["en"] = "en";
  Language2["se"] = "se";
  Language2["pt"] = "pt";
  Language2["es"] = "es";
  Language2["nl"] = "nl";
  return Language2;
})(Language || {});

// src/localization/i18n.ts
import i18n from "i18next";

// src/localization/locales/academy.json
var academy_default = {
  badge_active: "Active",
  badge_completed: "Completed",
  badge_notStarted: "Not started",
  badge_premium: "Premium",
  course_complete: "Course complete!",
  course_complete_2: "We're very proud of you",
  course_button_backToCourses: "Back to courses",
  course_module_count_singular: "{{count}} module",
  course_module_count_plural: "{{count}} modules",
  course_duration_count_singular: "{{count}} minute",
  course_duration_count_plural: "{{count}} minutes",
  course_continue_button: "Continue course",
  course_review_button: "Review course",
  course_start_button: "Start course",
  courses_title: "Explore courses",
  courses_none: "No courses here yet.",
  courses_none_contact: "Check back later or contact your administrator",
  courses_not_found: "Nothing found.",
  courses_not_found_try_again: "Check your search request or try again",
  courses_menu_in_progress: "In progress",
  courses_menu_not_started: "Not started",
  courses_menu_completed: "Completed",
  courses_menu_premium: "Premium",
  courses_searchBar: "Search for courses",
  duration_min: "{{count}} min",
  forgotPassword_info: "Enter your user email address for password recovery. Follow the instructions to reset your password.",
  forgotPassword_to_login: "Back to login",
  forgotPassword_send_instructions: "Send instructions",
  forgotPassword_resend_instructions: "Resend instructions",
  forgotPassword_instructions_sent: "Password recovery instructions sent to",
  forgotPassword_instructions_email_sent: "Instructions email sent!",
  forgotPassword_check_inbox: "Check your inbox",
  hero_welcome: "Welcome back, {{name}}!",
  hero_course_introduction: "Time to sharpen your skills in",
  login_button: "Log in",
  login_with_password: "Login with password",
  login_forgot_password: "Forgot password?",
  login_with_sso: "Log in with SSO",
  login_email_label: "Email Address",
  login_email_helper: "Enter email",
  login_password_label: "Password",
  login_password_helper: "Enter password",
  login_welcome: "Welcome to",
  module_button_submitAnswers: "Submit your answers",
  module_button_processingAnswers: "Processing your answers...",
  module_button_playVideo: "Play video",
  module_question_count_singular: "{{count}} question",
  module_question_count_plural: "{{count}} questions",
  module_status_completed: "Completed",
  module_status_todo: "To-do",
  module_feedback_moduleCompleted_title: "Great job!",
  module_feedback_moduleCompleted_description: "You've nailed this module and are ready for the next one",
  module_feedback_moduleCompleted_button: "Next module",
  module_feedback_courseCompleted_title: "Great job!",
  module_feedback_courseCompleted_description: "You've nailed this module and completed the course",
  module_feedback_courseCompleted_button: "Finish course",
  module_feedback_moduleNotCompleted_title: "You're almost there!",
  module_feedback_moduleNotCompleted_description: "Practice this module to get a perfect score",
  module_feedback_moduleNotCompleted_button: "Restart",
  module_video_notAvailable: "Video not available in the chosen language",
  module_text_notAvailable: "Text not available in the chosen language",
  module_video_instructions: "Watch the video and answer the questions to complete this module",
  module_image_instructions: "Look at the picture and answer the questions to complete this module",
  module_text_instructions: "Read the text below and answer the questions to complete this module",
  question_select_multiple: "Select all that apply",
  question_select_one: "Select one",
  paginationControls_pageInfo: "{{pageFirstItemCount}} - {{pageLastItemCount}} of {{totalCount}}",
  user_logout: "Log out",
  incorrect_password: "Incorrect password!",
  incorrect_email: "Incorrect email!",
  login_error_many_attempts: "Too many attempts! Please try again later.",
  login_error_session_expired: "Session expired.",
  login_error: "Something went wrong. Please try again later.",
  not_found_page_title: "We couldnt find this page or it does not exist",
  not_found_page_subtitle: "We don\u2019t have a course for this incident yet. But we can take you back to the homepage or your currently active course.",
  not_found_page_back_to_homepage: "Go back to homepage",
  go_to_course: "GO TO COURSE",
  select_language: "Select language",
  logging_out: "Logging out...",
  login_passwordMissing: "Enter password.",
  login_passwordMissingUppercase: "Password must contain at least 1 upper case letter.",
  login_passwordMissingLowercase: "Password must contain at least 1 lower case letter.",
  login_passwordMissingSpecialChar: "Password must contain at least 1 special symbol.",
  login_passwordMissingNumber: "Password must contain at least 1 numeric character.",
  login_password10Char: "Password has to be at least 10 characters long.",
  login_passwordsDoNotMatch: "Passwords don't match.",
  passwordReset_info: "Enter your new password and confirm it in the next field",
  passwordReset_new_password: "New password",
  passwordReset_type_password: "Type new password",
  passwordReset_confirm_password: "Confirm password",
  passwordReset_confirm_new_password: "Confirm new password",
  passwordReset_changed_success: "Password successfully changed. You can now log into the Cosafe Academy.",
  passwordReset_link_expired: "Your password reset link has expired. Please go back to the login page and press \u201CForgot password?\u201D button to receive a new link."
};

// src/localization/locales/se/academy.json
var academy_default2 = {
  badge_active: "Aktiv",
  badge_completed: "Avklarad",
  badge_notStarted: "Ej p\xE5b\xF6rjad",
  badge_premium: "Premium",
  course_complete: "Kursen \xE4r klar!",
  course_complete_2: "Vi \xE4r stolta \xF6ver dig",
  course_button_backToCourses: "Tillbaka till kurserna",
  course_module_count_singular: "{{count}} modul",
  course_module_count_plural: "{{count}} moduler",
  course_duration_count_singular: "{{count}} minut",
  course_duration_count_plural: "{{count}} minuter",
  course_continue_button: "Forts\xE4tt kurs",
  course_review_button: "Repetera kurs",
  course_start_button: "Starta kurs",
  courses_title: "Utforska kurser",
  courses_none: "Inga kurser h\xE4r \xE4nnu.",
  courses_none_contact: "Kom tillbaka senare eller kontakta din administrat\xF6r",
  courses_not_found: "Inget hittat.",
  courses_not_found_try_again: "Kontrollera din s\xF6kf\xF6rfr\xE5gan eller f\xF6rs\xF6k igen",
  courses_menu_in_progress: "Aktiva",
  courses_menu_not_started: "Ej p\xE5b\xF6rjade",
  courses_menu_completed: "Avklarade",
  courses_menu_premium: "Premium",
  courses_searchBar: "S\xF6k efter kurser",
  duration_min: "{{count}} min",
  forgotPassword_info: "Ange e-postadressen f\xF6r ditt konto f\xF6r att \xE5terst\xE4lla l\xF6senordet. F\xF6lj sedan instruktionerna.",
  forgotPassword_to_login: "Tillbaka till login",
  forgotPassword_send_instructions: "Skicka instruktioner",
  forgotPassword_resend_instructions: "Skicka om instruktioner",
  forgotPassword_instructions_sent: "Instruktioner f\xF6r att \xE5terst\xE4lla l\xF6senord har skickats till",
  forgotPassword_check_inbox: "Kontrollera din inkorg",
  forgotPassword_instructions_email_sent: "Instruktionsmejl skickat!",
  hero_welcome: "V\xE4lkommen tillbaka {{name}}!",
  hero_course_introduction: "Dags att v\xE4ssa dina kunskaper i",
  login_button: "Logga in",
  login_with_password: "Logga in med l\xF6senord",
  login_forgot_password: "Gl\xF6mt l\xF6senord?",
  login_with_sso: "Logga in med SSO",
  login_email_label: "E-postadress",
  login_email_helper: "Ange epost",
  login_password_label: "L\xF6senord",
  login_password_helper: "Ange l\xF6senord",
  login_welcome: "V\xE4lkommen till",
  module_button_submitAnswers: "Skicka in dina svar",
  module_button_processingAnswers: "Bearbetar dina svar...",
  module_button_playVideo: "Spela upp video",
  module_question_count_singular: "{{count}} fr\xE5ga",
  module_question_count_plural: "{{count}} fr\xE5gor",
  module_status_completed: "Klar",
  module_status_todo: "Att g\xF6ra",
  module_feedback_moduleCompleted_title: "Bra jobbat!",
  module_feedback_moduleCompleted_description: "Du har klarat denna modul och \xE4r redo f\xF6r n\xE4sta",
  module_feedback_moduleCompleted_button: "N\xE4sta modul",
  module_feedback_courseCompleted_title: "Bra jobbat!",
  module_feedback_courseCompleted_description: "Du har klarat denna modul och avslutat kursen",
  module_feedback_courseCompleted_button: "Avsluta kurs",
  module_feedback_moduleNotCompleted_title: "Du \xE4r n\xE4stan d\xE4r!",
  module_feedback_moduleNotCompleted_description: "\xD6va p\xE5 denna modul f\xF6r att f\xE5 ett perfekt resultat",
  module_feedback_moduleNotCompleted_button: "Starta om",
  module_video_notAvailable: "Videon finns inte tillg\xE4nglig p\xE5 det valda spr\xE5ket",
  module_text_notAvailable: "Texten finns inte tillg\xE4nlig p\xE5 det valda spr\xE5ket",
  module_video_instructions: "Titta p\xE5 videon och svara p\xE5 fr\xE5gorna f\xF6r att slutf\xF6ra modulen",
  module_image_instructions: "Titta p\xE5 bilden och svara p\xE5 fr\xE5gorna f\xF6r att slutf\xF6ra modulen",
  module_text_instructions: "L\xE4s texten nedan och svara p\xE5 fr\xE5gorna f\xF6r att slutf\xF6ra modulen",
  question_select_multiple: "V\xE4lj alla r\xE4tta svar",
  question_select_one: "V\xE4lj en",
  paginationControls_pageInfo: "{{pageFirstItemCount}} - {{pageLastItemCount}} av {{totalCount}}",
  user_logout: "Logga ut",
  incorrect_password: "Felaktigt l\xF6senord!",
  incorrect_email: "Felaktig e-postadress!",
  login_error_many_attempts: "F\xF6r m\xE5nga f\xF6rs\xF6k! F\xF6rs\xF6k igen senare.",
  login_error_session_expired: "Sessionen har g\xE5tt ut.",
  login_error: "N\xE5got gick fel. F\xF6rs\xF6k igen senare.",
  not_found_page_title: "Vi kunde inte hitta denna sida eller s\xE5 existerar den inte",
  not_found_page_subtitle: "Vi har \xE4nnu inte en kurs f\xF6r denna h\xE4ndelse. Men vi kan ta dig tillbaka till startsidan eller din nuvarande aktiva kurs.",
  not_found_page_back_to_homepage: "G\xE5 tillbaka till startsidan",
  go_to_course: "G\xC5 TILL KURS",
  select_language: "V\xE4lj spr\xE5k",
  logging_out: "Loggar ut...",
  login_passwordMissing: "V\xE4nligen ange ett l\xF6senord",
  login_passwordMissingUppercase: "L\xF6senordet m\xE5ste inneh\xE5lla minst en stor bokstav.",
  login_passwordMissingLowercase: "L\xF6senordet m\xE5ste inneh\xE5lla minst en liten bokstav.",
  login_passwordMissingSpecialChar: "L\xF6senordet m\xE5ste inneh\xE5lla minst en specialsymbol.",
  login_passwordMissingNumber: "L\xF6senordet m\xE5ste inneh\xE5lla minst ett numeriskt tecken.",
  login_password10Char: "L\xF6senordet m\xE5ste vara minst 10 tecken",
  login_passwordsDoNotMatch: "L\xF6senord matchar inte",
  passwordReset_info: "Ange ditt nya l\xF6senord och bekr\xE4fta det i n\xE4sta f\xE4lt",
  passwordReset_new_password: "Nytt l\xF6senord",
  passwordReset_type_password: "Skriv nytt l\xF6senord",
  passwordReset_confirm_password: "Bekr\xE4fta l\xF6senord",
  passwordReset_confirm_new_password: "Bekr\xE4fta nytt l\xF6senord",
  passwordReset_changed_success: "L\xF6senordet har \xE4ndrats. Du kan kan nu logga in p\xE5 Cosafe Academy.",
  passwordReset_link_expired: "YDin l\xE4nk f\xF6r \xE5terst\xE4llning av l\xF6senord har g\xE5tt ut. G\xE5 tillbaka till inloggningssidan och tryck p\xE5 knappen 'Gl\xF6mt l\xF6senord?' f\xF6r att f\xE5 en ny l\xE4nk."
};

// src/localization/i18n.ts
var locales = { en: academy_default, se: academy_default2 };
var defaultLanguage = "en";
var availableLanguageCodes = ["en", "se"];
var getAvailableLanguageCodes = () => {
  return availableLanguageCodes;
};
var getTranslationKey = (count, keyForSingular, keyForPlural) => {
  return count === 1 ? keyForSingular : keyForPlural;
};
var initI18n = (config, middlewares) => {
  if (middlewares?.length) {
    middlewares.forEach((middleware) => {
      i18n.use(middleware);
    });
  }
  i18n.init({
    resources: {
      en: {
        translation: academy_default
      },
      se: {
        translation: academy_default2
      }
    },
    lng: defaultLanguage,
    fallbackLng: defaultLanguage,
    ...config ?? {}
  });
};
export {
  constants_exports as Constants,
  enums_exports as Enums,
  utils_exports as Utils,
  availableLanguageCodes,
  defaultLanguage,
  getAvailableLanguageCodes,
  getTranslationKey,
  i18n,
  initI18n,
  locales
};
