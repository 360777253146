import { useTranslation } from 'react-i18next';
import { Images } from '../../assets/images';
import { Badge, Button, Card, Text } from '../../atoms';
import { BadgeType } from '../../atoms/Badge';
import { Row } from '../../atoms/Row';
import { getTranslationKey } from '../../i18n';
import {
  CourseStatus,
  CourseType,
  UserCourseResult,
} from '../../utils/interfaces';
import { getPercentageComplete } from '../../utils/interfaces/academy-api/UserCourseResult.helper';
import { InfoSet } from '../InfoSet';
import { ProgressBar } from '../ProgressBar';
import { getBadgeType } from './CourseCard.helper';
import styles from './CourseCard.module.css';

interface CourseCardProps {
  course: UserCourseResult;
  isShowBadge?: boolean;
  onStartCourse: () => void;
}

export const CourseCard = (props: CourseCardProps) => {
  const { course, isShowBadge = false, onStartCourse } = props;
  const { t } = useTranslation();

  const getInfoSet = () => {
    return (
      <InfoSet
        infoFields={[
          {
            text: t(
              getTranslationKey(
                course.moduleIds.length,
                'course_module_count_singular',
                'course_module_count_plural',
              ),
              { count: course.moduleIds.length },
            ),
            icon: 'document',
          },
          {
            text: t(
              getTranslationKey(
                course.totalDurationMin,
                'course_duration_count_singular',
                'course_duration_count_plural',
              ),
              { count: course.totalDurationMin },
            ),
            icon: 'clock',
          },
        ]}
      />
    );
  };

  const getButtonTextKey = (status: CourseStatus) => {
    switch (status) {
      case CourseStatus.Ongoing:
        return 'course_continue_button';
      case CourseStatus.Completed:
        return 'course_review_button';
      case CourseStatus.Todo:
      default:
        return 'course_start_button';
    }
  };

  return (
    <div className={styles.card}>
      <Card
        image={Images.PersonWithComputer}
        onClick={onStartCourse}
        disabled={!course.enabled}
      >
        <div className={styles.topRightContainer}>
          {course.status === CourseStatus.Ongoing && (
            <div className={styles.progressBar}>
              <ProgressBar
                value={getPercentageComplete(course)}
                maxValue={100}
                unit="%"
              />
            </div>
          )}
          {isShowBadge && (
            <div className={styles.badge}>
              <Badge type={getBadgeType(course)} />
            </div>
          )}
          {course.type === CourseType.Premium && (
            <div className={styles.badge}>
              <Badge type={BadgeType.premium} />
            </div>
          )}
        </div>
        <div className={styles.container}>
          <div className={styles.textContainer}>
            <Row>
              <div className={styles.title}>
                <Text
                  text={course.title}
                  textKey={course.titleKey}
                  fontSize="h3"
                  fontWeight="bold"
                  truncate="twoLines"
                />
              </div>
            </Row>
            <div className={styles.description}>
              <Text
                text={course.description}
                textKey={course.descriptionKey}
                fontSize="body"
                fontWeight="regular"
                truncate="tenLines"
              />
            </div>
            <div className={styles.infoSetContainer}>{getInfoSet()}</div>
          </div>
          <div className={styles.buttonContainer}>
            <Button
              textKey={getButtonTextKey(course.status)}
              size="full"
              type="primary"
            ></Button>
          </div>
        </div>
      </Card>
    </div>
  );
};
